<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.socialplatform.crud.edit
          : $lang.socialplatform.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.socialplatform.name.$error }"
          >
            <label class="form__label"
            >{{ $lang.socialplatform.form.name }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.name"
                :placeholder="$lang.socialplatform.form.name"
                class="form-control"
                v-model="socialplatform.name"
            />
            <small
                class="error"
                v-if="
                $v.socialplatform.name.$error &&
                !$v.socialplatform.name.required
              "
            >{{ $lang.socialplatform.validation.required.name }}</small
            >
            <small
                class="error"
                v-if="
                $v.socialplatform.name.$error &&
                !$v.socialplatform.name.minLength
              "
            >{{ $lang.socialplatform.validation.minLength.name }}
              {{ $v.socialplatform.name.$params.minLength.min }}
              {{ $lang.socialplatform.validation.common.letter }}</small
            >
          </div>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.socialplatform.url.$error }"
          >
            <label class="form__label"
            >{{ $lang.socialplatform.form.url }}
              <required_span/>
            </label>
            <input
                type="url"
                pattern="https://.*"
                size="30"
                required
                :placeholder="$lang.socialplatform.form.url"
                :maxlength="maxlength.url"
                class="form-control"
                v-model="socialplatform.url"
            />
            <small
                class="error"
                v-if="
                $v.socialplatform.url.$error && !$v.socialplatform.url.required
              "
            >{{ $lang.socialplatform.validation.required.url }}</small
            >
            <small
                class="error"
                v-if="
                $v.socialplatform.url.$error && !$v.socialplatform.url.minLength
              "
            >{{ $lang.socialplatform.validation.minLength.url }}
              {{ $v.socialplatform.url.$params.minLength.min }}
              {{ $lang.socialplatform.validation.common.letter }}</small
            >
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.socialplatform.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-socialPlatform')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Social Platform')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >

          <v-server-table
              :columns="columns"
              url="/social-platforms/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Social Platform')"
          >
            <template #name="data">
              <span :title="data.row.name">{{
                  trimfunction(data.row.name, 50)
                }}</span>
            </template>
            <template #url="data">
              <span :title="data.row.url">{{
                  trimfunction(data.row.url, 50)
                }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-socialPlatform')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-socialPlatform')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {socialplatform} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {minLength, required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "SocialPlatform",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: socialplatform,
      socialplatform: {
        name: "",
        url: "",
      },
      maxlength: {
        name: Maxlength.socialplatform.name,
        url: Maxlength.socialplatform.url,
      },
      columns: ["name", "url", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.socialplatform.table.name,
          url: this.$lang.socialplatform.table.url,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name"],
        filterable: ["name"],
      },
    };
  },
  validations: {
    socialplatform: {
      name: {
        required,
        minLength: minLength(1),
      },
      url: {
        required,
        minLength: minLength(1),
      },
    },
  },
  mounted() {
    let self = this;
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.socialplatform.name = "";
      self.socialplatform.url = "";
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data.data;
              self.socialplatform.name = responseData.name;
              self.socialplatform.url = responseData.url;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    rowClicked(item, index) {
      this.$router.push({path: `socialplatform/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
    onSubmit() {
      let self = this;

      this.$v.socialplatform.$touch();
      if (this.$v.socialplatform.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.socialplatform.name,
              url: self.socialplatform.url,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.socialplatform.name,
              url: self.socialplatform.url,
              isActive: "1",
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
